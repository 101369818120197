import { defineStore } from 'pinia';

export const usePokerStore = defineStore('poker', {
	state: () => ({
		sessionId: '',
		user: {
			name: '',
			id: ''
		}
	}),
	actions: {
		setSessionId(sessionId) {
			this.sessionId = sessionId;
		},
		setUser(user) {
			this.user = user;
		}
	}
});