<template>
	<div class="form">
		<div>
			<input v-model="name" placeholder="Enter your name" />
		</div>
		<div>
			<input v-model="sessionId" placeholder="Enter the SessionId (optional)" />
		</div>
		<div class="center">
			<button @click="createLobby">Create Room</button>
			<button @click="joinLobby">Join</button>
			<div v-if="isLoading" class="loading-circle"></div>
		</div>
	</div>
</template>

<script setup>

</script>

<script>
import { usePokerStore } from '@/store/poker-store';

export default {
	setup() {
		const pokerStore = usePokerStore();

		return {pokerStore};
	},
	data() {
		return {
			name: '',
			sessionId: '',
			isLoading: false,
		};
	},
	methods: {
		async createLobby() {
			this.isLoading = true;
    	const sessionId = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000; // Generate a random number between 10000 and 99999
			this.createUserAndSetSessionInStore(sessionId);
			this.$router.push({ path: '/poker/' + sessionId });
		},
		joinLobby() {
			this.isLoading = true;
			this.$router.push({ path: '/poker/' + this.sessionId });
		},
		createUserAndSetSessionInStore(sessionId) {
			const userId = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000; // Generate a random number between 10000 and 99999
			this.pokerStore.setSessionId("" + sessionId);
			this.pokerStore.setUser({ name: this.name, id: userId });
		}
	},
};
</script>

<style scoped>
.loading-circle {
	width: 50px;
	height: 50px;
	border: 5px solid var(--md-sys-color-outline);
	border-top-color: var(--md-sys-color-primary);
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.center {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

button {
	background-color: var(--md-sys-color-primary);
	border: none;
	border-radius: 10px;
	color: var(--md-sys-color-on-primary);
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
}

input {
	background-color: var(--md-sys-color-surface);
	box-sizing: border-box;
	border: 1px solid var(--md-sys-color-outline);
	border-radius: 10px;
	color: var(--md-sys-color-on-surface);
	padding: 15px 15px;
	text-align: left;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	width: 100%;
}

input:focus {
	outline: none;
}

.form {
	width: 80%;
	max-width: 690px;
	margin: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
}
</style>