<template>
	<div :class="isDark ? 'dark': 'light'">
		<div class="background">
			<div id="heading" class="box">
				Pointing Poker by Joshi
			</div>

			<canvas @click="toggleTheme" ref="canvas" width="400" height="300" class="theme-switch"></canvas>
		
			<router-view/>

			<Cookies ref="cookies"></Cookies>
		</div>
		<Footer @openCookieSettings="openCookies"></Footer>
	</div>
</template>

<script>
import './styles/dark.css';
import './styles/light.css';
import './styles/spielekiste.css';

import { Rive, Layout, Fit, Alignment } from '@rive-app/canvas';

import Cookies from '@/components/Cookies.vue';
import Footer from '@/components/Footer.vue';

export default {
	name: 'App',
	components: {
		Cookies,
		Footer
	},
	data() {
		return {
			isDark: false,
			cookieSettings: true,
		}
	},
	created() {
		const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    	const savedTheme = JSON.parse(localStorage.getItem('isDark'));
    	if (savedTheme === null) {
      		this.isDark = userPrefersDark;
    	} else {
      		this.isDark = savedTheme;
    	}
	},
	mounted() {
		const rive = new Rive({
			canvas: this.$refs.canvas,
			src: "/light_dark_switcher.riv",
			stateMachines: "SM",
			layout: new Layout({
				fit: Fit.FitWidth,
				alignment: Alignment.Center
			}),
			autoplay: true,
			onLoad: () => {
				const inputs = rive.stateMachineInputs('SM');

				const go = inputs.find(i => i.name === 'go');
				go.value = !this.isDark;
			}
		})
	},
	watch: {
		isDark(newVal) {
      		localStorage.setItem('isDark', JSON.stringify(newVal));
    	}
	},
	methods: {
		toggleTheme() {
			this.isDark = !this.isDark;
		},
		openCookies() {
			this.$refs.cookies.showBanner = true;
		}
	}
}
</script>

<style>
@font-face {
	font-family: 'Roboto';
	src: url('./assets/Roboto-Regular.ttf') format('truetype');
}

body {
	margin: 0;
}

#app {
	font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.background {
	box-sizing: border-box;
	width: 100vw;
	min-height: 100vh;
	padding: 10px;
	background-color: var(--md-sys-color-background);
	transition: 1s;
	transition-timing-function: ease-in-out;
}

.box {
  	background-color: var(--md-sys-color-secondary);
	color: var(--md-sys-color-on-secondary);
  	border-radius: 10px;
  	padding: 20px;
  	text-align: center;
  	width: 80%;
  	max-width: 690px;
	box-sizing: border-box;
  	margin: 0 auto;
	font-size: 24px;
}

.theme-switch {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	fill: var(--md-sys-color-on-surface);
}

.theme-active {
	background-color: var(--md-sys-color-tertiary);
	border-radius: 50%;
	box-sizing: border-box;
}

@media (max-width: 600px) {
  .box {
    width: 90%;
  }
}
</style>
